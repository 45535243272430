<template>
  <Modal v-model="printSkuBarcodeModal" :title="$t('key1000414')" width="500" :transfer="false"
    :mask-closable="false" @on-visible-change="printSkuBarcodeChange">
    <h2 class="mb20 ml22">{{ ymsSku }}</h2>
    <Form ref="printSkuBarcodeForm" :model="printSkuBarcodeForm" :rules="ruleValidate" :label-width="90" @submit.native.prevent>
      <Row type="flex">
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <FormItem :label="$t('key1000871')" prop="printNum">
            <InputNumber :max="quantity" :min="1" v-model.trim="printSkuBarcodeForm.printNum"
              :formatter="value => `${parseInt(value)}`" style="width: 200px;"></InputNumber>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button @click="printSkuBarcodeModal = false">{{ $t('key1000097') }}</Button>
      <Button type="primary" @click="printSKUBarcodeBtn">{{ $t('key1000414') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';

export default {
  name: 'printSkuBarcodeModal',
  mixins: [Mixin],
  data() {
    return {
      printSkuBarcodeModal: false,
      printSkuBarcodeForm: {
        printNum: null
      },
      ymsSku: null,
      quantity: null,
      ruleValidate: {
        printNum: [
          {type: 'number', required: true, message: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005585'), trigger: 'change'}
        ]
      },
    }
  },
  methods: {
    // 初始化数据
    initPrintSkuBarcodeData(obj) {
      this.printSkuBarcodeModal = true;
      this.ymsSku = obj._productInfo.ymsSku;
      this.quantity = obj.quantity;
    },
    // 打印条码
    printSKUBarcodeBtn() {
      let v = this;
      let path = v.$route.path;
      let url = '';
      switch (path) {
        case '/stockOrderDetails':
          url = api.post_packageInfo_appendYmsSkuExpressBillStcok;
          break;
      }
      let query = {
        quantity: v.printSkuBarcodeForm.printNum,
        sku: v.ymsSku,
        type: 2 // 打印类型 1 首次打印  2 批量打印
      };
      v.$refs['printSkuBarcodeForm'].validate((valid) => {
        if (valid) {
          v.axios.post(url, query, {loading: true, loadingText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000212')}).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas;
              if (data) {
                let filenodeFullUrl = data.filenodeFullUrl || '';
                if (filenodeFullUrl) {
                  v.commonPrint(filenodeFullUrl, v.ymsSku, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003846')).then(() => {
                    v.printSkuBarcodeModal = false;
                  });
                } else {
                  v.$Message.warning(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005540'));
                  return false;
                }
              }
            }
          });
        }
      });
    },
    // 监听弹窗
    printSkuBarcodeChange(value) {
      if (!value) {
        this.ymsSku = null;
        this.$refs['printSkuBarcodeForm'].resetFields();
      }
    }
  }
};
</script>

<style lang="less">

</style>
